<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button :text="'Zurück'"></ion-back-button>
        </ion-buttons>
        <ion-title size="small">
          {{ title }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="ion-padding">
        <div
          v-html="topic.content.rendered"
          v-if="topic.content"
          id="content"
        ></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonTitle,
} from "@ionic/vue";

export default {
  name: "Detail",
  data() {
    return {
      topic: {},
    };
  },
  computed: {
    title() {
      if (this.topic.title) return this.topic.title.rendered;
      return "Title";
    },
  },
  methods: {
    async ionViewWillEnter() {
      this.fetchData(this.$route.params.type, this.$route.params.id);
    },
    async fetchData(type, id) {
      const { data } = await this.$http.get(`wp/v2/${type}/${id}`);
      this.topic = data;
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonTitle,
  },
};
</script>

<style scoped>
ion-back-button::part(text) {
  color: black;
}

#content:deep() .wp-block-button {
  margin-top: 20px;
}

#content:deep() .wp-block-button__link {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

#content:deep() img {
  max-width: 100%;
  height: auto;
  border: 0;
}

#content:deep() figure {
  margin: 0;
}

#content:deep() .wp-block-table table {
  table-layout: fixed;
  width: auto;
  overflow: scroll;
  display: block;
}

#content:deep() .wp-block-table tbody td {
  border-right: 5px solid white;

  padding: 0.5rem;
  min-width: 120px;
}

#content:deep() .wp-block-table tbody tr:nth-child(odd) {
  background-color: rgb(255, 237, 153);
}

#content:deep() .wp-block-table tbody tr:first-child {
  font-weight: bold;
  background-color: rgb(255, 229, 100);
}

#content:deep() .wp-block-table tbody tr td:first-child {
  font-weight: bold;
}
</style>